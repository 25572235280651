import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="118" height="9" viewBox="0 0 118 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.938 9C19.4984 8.16452 1.63513 9.09727 0.336714 8.84548C-0.723318 8.50786 0.970705 2.5622 1.95466 1.68666C2.29955 1.37192 2.70023 1.32616 3.61825 1.46922C5.07389 1.73818 23.8958 1.20598 23.8958 1.20598C24.1443 1.38909 24.8899 0.77107 25.0268 0.994246C25.458 1.51499 55.9656 0.805402 58.5675 0.77679C60.1449 0.759622 58.8972 1.11441 60.7535 0.971352C64.7096 0.685228 73.3319 0.959909 76.3294 0.633728C78.7284 0.364771 79.2457 0.353335 81.5484 0.570789C83.9017 0.765353 96.4142 0.484949 97.8445 0.0672084C98.1944 -0.0472411 98.4632 -0.00718032 98.4632 0.124437C98.4632 0.153049 107.283 0.250328 107.324 0.353332C107.39 0.484949 107.659 0.547892 107.907 0.456332C108.369 0.318993 117.757 -0.178864 117.904 0.204542C118.365 1.30326 117.072 5.98424 115.982 7.12301C114.866 8.3419 108.739 6.97424 103.814 7.70672L80.884 7.78111L75.9693 8.2389C75.4063 7.84405 73.2406 8.83976 71.932 8.13017C71.3792 7.84977 67.4738 7.80971 66.6116 8.10728C65.8609 8.33618 45.8826 7.75821 43.8335 8.55363C40.1818 7.88982 25.3717 8.83977 21.938 9Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
    width="127" height="9" viewBox="0 0 127 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.6113 9C20.9856 8.16452 1.75984 9.09727 0.362396 8.84548C-0.778487 8.50786 1.04474 2.5622 2.10374 1.68666C2.47494 1.37192 2.90618 1.32616 3.89422 1.46922C5.46088 1.73818 25.7184 1.20598 25.7184 1.20598C25.9858 1.38909 26.7883 0.77107 26.9357 0.994246C27.3997 1.51499 60.2341 0.805402 63.0345 0.77679C64.7322 0.759622 63.3893 1.11441 65.3872 0.971352C69.6451 0.685228 78.925 0.959909 82.1511 0.633728C84.7331 0.364771 85.2899 0.353335 87.7682 0.570789C90.301 0.765353 103.768 0.484949 105.307 0.0672084C105.684 -0.0472411 105.973 -0.00718032 105.973 0.124437C105.973 0.153049 115.466 0.250328 115.51 0.353332C115.581 0.484949 115.87 0.547892 116.137 0.456332C116.634 0.318993 126.738 -0.178864 126.897 0.204542C127.393 1.30326 126.001 5.98424 124.828 7.12301C123.627 8.3419 117.033 6.97424 111.732 7.70672L87.0531 7.78111L81.7635 8.2389C81.1576 7.84405 78.8267 8.83976 77.4184 8.13017C76.8233 7.84977 72.6201 7.80971 71.6921 8.10728C70.8842 8.33618 49.3821 7.75821 47.1768 8.55363C43.2465 7.88982 27.3069 8.83977 23.6113 9Z" fill="#FFA700"/>
      </svg>
      
  </span>
)