import React from "react"
import image from "./image/foto.webp"
import { TitleUnderlineOne } from "./titleUnderline"

const BecauseWe = () => {
  return (
    <section className="container-page-soporte-becauseWe">
      <section className="section-img-because">
        <img className="img-because" src={image} alt="img-because" loading="lazy" />
      </section>
      <section className="description-becausewe">
        <p className="container-page-soporte-becauseWe-title">     
        <TitleUnderlineOne underline="¿Por qué lo" />     hacemos? 
          </p>
        <p className="text-body-becausewe">
          Detrás de un buen producto hay un buen soporte para todos los clientes. Desde el día 1 en que estés con nosotros, siempre estaremos presentes para ayudarte paso a paso en los nuevos procesos de tu empresa.
          <br /><br />
          Creemos que nuestra meta no solo está en adquirir más clientes, sino en tratarlos bien, ofrecer el respeto que se merecen y de estar agradecidos por su confianza con nosotros. Por eso es que siempre estamos con cada uno de ellos.
        </p>
      </section>
    </section>
  )
}

export default BecauseWe
