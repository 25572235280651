import React, { useState, useLayoutEffect } from "react"
import { TitleUnderlineOne } from "./titleUnderline"


const NavSupport = ({ data }) => {
    //estado para el texto de la caja en desktop
    const [text, settext] = useState(
        "Nos enorgullece contar con un equipo de profesionales altamente capacitados que brinda el apoyo que nuestros clientes necesitan, sin costos adicionales."
    )
    const support = (event, text) => {
        event.preventDefault()
        settext(text)
    }

    //Activar estilo para primer boton al cargar la pagina
    useLayoutEffect(() => {
        var header = document.getElementById("myDIV")
        var btns = header.getElementsByClassName("btn-support")
        for (var i = 0; i < btns.length; i++) {
            btns[i].addEventListener("click", function () {
                var current = document.getElementsByClassName("active")
                current[0].className = current[0].className.replace(" active", "")
                this.className += " active"
            })
        }
    })

    return (
        <section className="container-navSupport-pages-soporte">
            <section className="background-support" id="myDIV">
                <section className="section-support">
                    <p className="container-page-soporte-becauseWe-title">
                        <TitleUnderlineOne underline="Experiencia" /> de Soporte
                    </p>

                    <section id="mainmenu">
                        <ul className="support">
                            {data.map((elemento, i) => {
                                return (
                                    <li key={i} >
                                        <button
                                            className={`btn-support ${elemento.active}`}
                                            onClick={event => {
                                                support(event, elemento.text)
                                            }}
                                        >
                                            {elemento.title}
                                        </button>
                                    </li>
                                )
                            })}
                        </ul>
                    </section>
                    <p className="text-support">{text}</p>

                    {/* <!-- Menu Mobile--> */}
                    <section className="section-acordion">
                        <AccordionList accordions={data} />
                    </section>
                </section>
            </section>
        </section>
    )
}
export default NavSupport

class AccordionList extends React.Component {
    state = {
        activeAccordion: 0,
    }

    headerClick = index =>
        this.setState(prev => ({
            activeAccordion: prev.activeAccordion === index ? -1 : index,
        }))

    render() {
        const accordionElement = this.props.accordions.map((item, index) => (
            <div key={index}>
                <AccordionItem
                    accordion={item}
                    isOpen={this.state.activeAccordion === index}
                    onHeaderClick={this.headerClick.bind(null, index)}
                />
            </div>
        ))

        return <div className="contenedor-acordeon">{accordionElement}</div>
    }
}

const AccordionItem = ({ accordion, onHeaderClick, isOpen }) => {
    return (
        <div className="">
            {/* eslint-disable-next-line */}
            <div
                className={`accordion-header ${isOpen ? "active" : ""}`}
                onClick={onHeaderClick}
            >
                {accordion.title}
            </div>
            {isOpen && (
                <div className="accordion-body">
                    <p className="accordion-text">{accordion.text}</p>
                </div>
            )}
        </div>
    )
}
