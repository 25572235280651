/* eslint-disable react/jsx-key */
import React from "react"

// logos testimonios
import Culqui from "@components/sharedComponents/testimonies/images/logos/culqi.svg"
import Kontigo from "@components/sharedComponents/testimonies/images/logos/kontigo.svg"
import Costagas from "@components/sharedComponents/testimonies/images/logos/costagas.svg"
import Cafae from "@components/sharedComponents/testimonies/images/logos/cafae.svg"

//foto testimonio desktop
import testimonyone from "@components/pageSoporte/components/testimonios/image/testimonyCulqui.webp"
import testimonytwo from "@components/pageSoporte/components/testimonios/image/testimonyKontigo.webp"
import testimonythree from "@components/pageSoporte/components/testimonios/image/testimonyCostagas.webp"
import testimonyfour from "@components/pageSoporte/components/testimonios/image/testimonyCafae.webp"

// //iconos de modulos
import conexion from "@components/pageSoporte/components/modules/image/conexion.svg"
import presentacion from "@components/pageSoporte/components/modules/image/presentacion.svg"
import empatia from "@components/pageSoporte/components/modules/image/empatia.svg"
import imageVideo from "@components/pageSoporte/components/imgVideo.jpg"


let data = {
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  testimonies: [
    {
      testimony:
        "Se siente el respaldo y ayuda en todo momento del equipo de Beex. La agilidad en sus respuestas y procesos de soporte generan confianza y esto se demuestra en nuestros resultados obtenidos.",
      img: testimonyone,
      logo: Culqui,
      name: "Paul Vera",
      post: "Jefe de telemarketing de Culqi",
      link: "/casos-de-exito/culqi/"
    },
    {
      testimony:
        "Con Beex nos sentimos confiados, sumado a las plataformas robustas que nos brindan para mejorar nuestra operación, comparten un gran respaldo en soporte ante cualquier necesidad, de la manera más amable y rápida.",
      img: testimonytwo,
      logo: Kontigo,
      name: "Analucía Siguas Velásquezy",
      post: "Gerente de Marketing y Producto en GMoney",
      link: "/casos-de-exito/kontigo/"
    },

    {
      testimony:
        "Desde el inicio y con el acompañamiento adecuado de Beex, nuestro equipo logró sacar provecho a las diferentes funcionalidades de sus soluciones. Esto nos ayudó a mejorar los tiempos de espera, respuesta e índices de ventas.",
      img: testimonythree,
      logo: Costagas,
      name: "Natalia Pachas",
      post: "Jefa de Marketing de Costagas",
      link: "/casos-de-exito/costagas/"
    },
    {
      testimony:
        "El soporte de Beex es genial. Siempre responden de inmediato a las consultas sobre el manejo de sus soluciones. Realmente todo va muy bien, teniendo una estabilidad ideal para nuestros procesos de atención omnicanal.",
      img: testimonyfour,
      logo: Cafae,
      name: "Johans Herencia",
      post: "Responsable de Marketing Digital de CAFAE-SE",
      link: "/casos-de-exito/cafae-se/"
    },

  ],
  support: [
    {
      title: "Equipo",
      text:
        "Nos enorgullece contar con un equipo de profesionales altamente capacitados que brinda el apoyo que nuestros clientes necesitan, sin costos adicionales.",
      active: "active",
    },
    {
      title: "SLA",
      text:
        "Brindamos un estricto acuerdo de nivel de servicio, las 24 horas del día, los 7 días de la semana. No esperes tiempos largos de atención o respuestas que deberían ser rápidas por el tipo de operación que manejas.",
    },
    {
      title: "Canales",
      text:
        "Contáctanos por el medio preferido, como WhatsApp, Email o llamadas telefónicas. Siempre estamos prestos a atenderte para que tu operación no tenga pausa bajo ninguna circunstancia.",
    },
    {
      title: "Experiencia",
      text:
        "Nos gusta atender a nuestros clientes de manera excepcional, ganarnos su confianza y así fortalecer nuestras relaciones comerciales. Te brindamos una experiencia personalizada que optimiza la continuidad de tu negocio.",
    },
  ],

  cards: [
    {
      title: "La omnicanalidad es nuestra base ",
      img: conexion,
      info:
        "No solo nos conformamos con un solo canal. Nuestro servicio de atención es 100% omnicanal. Ya sea por chat o llamada, la esencia de nuestra ayuda nunca se perderá. Solo escoge el canal que prefieras y nos adaptamos a ti. ",
    },
    {
      title: "Capacitación como pan de cada día ",
      img: presentacion,
      info:
        "Creemos que la capacitación es la base de todo aprendizaje. Por eso, nosotros capacitamos a nuestros clientes sin costos adicionales. Lo que más nos interesa es volverlos unos super expertos en nuestras soluciones.",
    },
    {
      title: "La empatía es nuestra base",
      img: empatia,
      info:
        "Siempre nos ponemos de lado del cliente para que sus dudas sean resueltas en el menor tiempo posible. Estamos para entenderte y ayudarte en todo momento. No dudes de nosotros.",
    },
  ],

  contacts: {
    title: "¿Preparad@ para una experiencia inolvidable?",
    textbody:
      "Ponte en contacto con nosotros en cualquier momento, para nuestros clientes favoritos como tú, nunca habrá descanso. ",
    btn: "Quiero hablar con un experto",
    href: "/hablemos/",
  },
  bannerVideo: {
    title: "¿Cuál es el proceso de soporte y soluciones de Beex?",
    description: "<p> Descubre cómo Beex maneja cada etapa del soporte y las soluciones que ofrece. Conoce las herramientas y técnicas que utilizan para garantizar la satisfacción de los clientes. <br/><br/> Observa cómo se abordan los desafíos y qué pasos se siguen para resolver cualquier inconveniente. Aprende sobre el enfoque y los métodos que Beex emplea para mejorar la experiencia de cada usuario.</p>",
    image: imageVideo
},
}

export default data


