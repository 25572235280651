import React from "react"
import { TitleUnderlineOne } from "./titleUnderline"

const Modules = ({ data }) => (
  <>
    <section className="container-modules-pilares">
    <p className="container-modules-pilares-title">
    ¡Nuestros     <TitleUnderlineOne underline="pilares" /> de atención!</p>

      <div className="row-modules-agile">
        {data.map(elemento => {
          return (
            <div
              key={elemento.title}
              className="module-agile"
              style={{boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)"}}
            >
              <div className="modules-agile">
                <div className="modules-pagescasos-img">
                  <img
                    loading="lazy"
                    src={elemento.img}
                    className="modules-icon"
                    alt="..."
                  />
                </div>
                <h3 className="title-agile">{elemento.title} </h3>
                <p className="body-agile">{elemento.info}</p>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  </>
)
export default Modules
