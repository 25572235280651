import React from "react"
import { Link } from "gatsby"
import { TitleUnderlineOne, TitleUnderlineTwo } from "./titleUnderline"


const infoContact = ({ data }) => {
  return (
    <section className="container-info-pagessupport container-info-chatbots">
      <div className="section-contact-whatsapp">
        <p className="container-page-soporte-becauseWe-title">
          ¿Quieres hablar con <TitleUnderlineOne underline="nuestro" /> <TitleUnderlineTwo underline="equipo?" />
        </p>
        <p className="text-info-chatbots">Nosotros te garantizamos una atención 24/7. Así que, sea la hora en la que te encuentres, nuestro equipo estará para atenderte.</p>
        <Link
          href="/hablemos/"
          target="_blank"
          rel="noopener noreferrer"
          className="container-info-pagessupport-button"
        > Contactar con soporte
        </Link>
      </div>
    </section>
  )
}

export default infoContact
